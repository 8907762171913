
import 'reflect-metadata'
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { config } from '@/config'
import { AuthenticateApi } from "@/api"


const Auth = namespace('authentication')

@Component
export default class Login extends Vue { 
    loading = false
    errorLogin = false
    appVersion = config.version
    apiVersion = ""
   
    LogOutObj = {
        email : ""
    }
    async mounted() {
        await await AuthenticateApi.LogOutAd()
        localStorage.clear()
        this.$router.push('/login')
    }

    @Auth.Action
    private AUTHINFO!: (auth: any) => Promise<any>

}
