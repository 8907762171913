import Vue from 'vue'
import VueRouter, { RouteConfig, NavigationGuardNext, Route } from 'vue-router'
import Login from '../views/Login.vue'
import LogOut from '../views/LogOut.vue'
import AuthenEmail from '../views/AuthenEmail.vue'
 
import App from '../App.vue'
import { LogOutAd } from '@/api/AuthenticateApi'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
		path: '/login',
		name: 'Login',
		component: Login
  },
  {
		path: '/logout',
		name: 'logout',
		component: LogOut
  },
  {
		path: '/AuthenEmail',
		name: 'AuthenEmail',
		component: AuthenEmail
	},
  {
    path: '/',
    name: 'App',
    meta: { requiresAuth: true }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to: Route, from: Route, next: NavigationGuardNext) => {
	const isAuth = localStorage.getItem('isAuth')
	if (to.name == "Login") {
		if (isAuth === 'authorized') {
			next({
				path: '/',
				query: { redirect: to.fullPath }
			})
		}
	}
	
	if (to.matched.some(record => record.meta.requiresAuth)) {
		if (isAuth !== 'authorized') {
			next({
				path: '/login',
				query: { redirect: to.fullPath }
			})
		} else {
			next()
		}
	} else {
		next() 
	}
})

export default router
