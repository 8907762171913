import { HttpRequest } from './HttpRequest'
import { PublicClientApplication, InteractionRequiredAuthError, PopupRequest, AccountInfo, AuthenticationResult } from '@azure/msal-browser'
import { config } from "@/config"
import { LogOutPromtoion } from './LoginPromotion';
import * as CryptoJS from 'crypto-js';
import axios from 'axios';

 export const msalInstance = new PublicClientApplication({
    auth: {
        clientId: config.azureAd.clientId,
        authority: config.azureAd.authority
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: true
    },
}) 
export const LoginAD = async () => {
    try {
        const loginRequest: PopupRequest = {
            scopes: config.azureAd.scopes
        }

        const loginResponse: AuthenticationResult = await msalInstance.loginPopup(loginRequest);
        localStorage.setItem('isAuthPromotion', loginResponse.account.name)
        console.log(JSON.stringify(loginResponse))

        debugger
        // const data = CryptoJS.AES.encrypt(loginResponse.accessToken, "123#$%").toString()
        // console.log("Encrypt : " + data)
        
        // const decryptData = CryptoJS.AES.decrypt(data, "123#$%" ).toString(CryptoJS.enc.Utf8);
        // console.log("Decrypt : " + decryptData)
        
    } catch (err: any) {
        throw new Error
    }
}

export const LogOutAd = async () => {
    await msalInstance.logoutPopup();
    //await LogOutPromtoion('{ "email" : "' + localStorage.getItem('isAuthPromotion') +'" ');
    localStorage.removeItem('isAuthPromotion')
}

export const GetAcquireToken = async () => {
    const request = {
        account: msalInstance.getAllAccounts()[0],
        scopes: config.azureAd.scopes,
        forceRefresh: false
    }
    try {
		const response = await msalInstance.acquireTokenSilent(request);
        localStorage.setItem('isAuthPromotion', response.account.name)
		return response.account.name;            
	} catch (error) {
		if (error instanceof InteractionRequiredAuthError) {
			return msalInstance.acquireTokenPopup(request).catch((popupError) => {
				console.error(popupError);
			});
		}
		return false;
	}
}

export const GetAcquireTokenRead = async () => {
    const request = {
        account: msalInstance.getAllAccounts()[0],
        scopes: ["User.Read"],
        forceRefresh: false
    }
    try {
		const response = await msalInstance.acquireTokenSilent(request);
        localStorage.setItem('TOKEN_GRAPHMICROSOFT', response.accessToken)
		return response.accessToken;            
	} catch (error) {
		if (error instanceof InteractionRequiredAuthError) {
			return msalInstance.acquireTokenPopup(request).catch((popupError) => {
				console.error(popupError);
			});
		}
		return false;
	}
}

export const GetIsAuthenticated = async () => {
    try {
        const accounts: AccountInfo[] = msalInstance.getAllAccounts()
        await GetUserInfo();
        return accounts && accounts.length > 0
    } catch (error) {
        return false
    }
    
}

export const GetUserInfo = async () => {
    try { 
        const response = await HttpRequest().get(`/users/info`)
        if (response.data.message === 'OK') {
            const result: IGetUserInfo = response.data.responseData
            console.log("response.data.responseData : " + JSON.stringify(result))
            return result
        }
        return null
    } catch (error: any) {
        throw error
    }
}

export const Login = async (login: { email: string, password: string }) => {
    const data = JSON.stringify(login);
    try {
        const response = await HttpRequest().post(`/users/authenticate`, data, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        if (response.data.message === 'success') {
            return response.data.responseData[0]
        }
    } catch (error: any) {
        if (error.response.status === 401) return error.response.data.message
        throw new Error('error api /users/authenticate')
    }
}
 

export const AutenSystem = async (obj: any) => {
    const data = JSON.stringify(obj);
    try {
        const response = await HttpRequest().post(`/fgf/authensystem`, data, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        if (response.data.message === 'OK') {
            return response.data.responseData
        }
    } catch (error: any) {
        if (error.response.status === 401) return error.response.data.message
        throw new Error('error api /users/authenticate')
    }
}

export const getCliamToken = async (token: string) => {
 
    try {

        const response = await axios.get(`https://graph.microsoft.com/v1.0/me`,  
         {
            headers: {
                'Content-Type': 'application/json',
                'authrorize' : token
            }
        })
        if (response.data.message === 'OK') {
            return response.data.responseData
        }
    } catch (error: any) {
        if (error.response.status === 401) return error.response.data.message
        throw new Error('error api getCliamToken')
    }
}


export interface IGetUserInfo {
    userId: number
    userCode: string
    email: string
    firstName: string
    lastName: string
    firstNameth: string
    lastNameth: string
    allProjectFlag: number
    roleCode: any
    roleName: any
    deptCode: string
  }